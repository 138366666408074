@import 'src/styles/variables';

.qu-services {
  &-list {
    width: 335px;
  }

  &-item {
    position: relative;
    border-radius: 10px;
    border: 1px solid rgba($border-default, 0.3);
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .checked-icon {
      display: none;
    }

    &.selected {
      border: 2px solid $primary;

      .checked-icon {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 64px 64px 0;
        border-color: transparent $primary transparent transparent;
        z-index: 1;

        svg {
          position: absolute;
          top: 6px;
          right: -56px;
          width: 24px;
          color: $orange-light;
        }
      }
    }
  }

  &-body {
    position: relative;
    display: flex;
    padding: 15px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &-name {
    color: $text-black;
    font-size: 15px;
    font-weight: 600;
    line-height: 19px;
  }

  &-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 19px;
    color: $list-gray;
  }

  &-info {
    position: absolute;
    display: flex;
    top: 10px;
    right: 10px;
    color: $gray-icon;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &-type {
    margin-right: 5px;
  }

  &-prise {
    color: $text-black;
  }

  &-thumbnail {
    width: 100px;
    min-width: 100px;
    height: 100px;
    margin-right: 15px;
    background-color: $modal-bg;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }

  &-input {
    .ant-input-suffix {
      color: rgba($text-black, 0.3);
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border-top: 1px solid rgba($border-default, 0.3);
  }

  &-value {
    flex: 1;
    margin-left: 15px;
    font-size: 15px;
    font-weight: 600;
    line-height: 19px;
  }

  &-controls {
    padding-right: 8px;

    .qu-button {
      border: none;
    }

    .remove-button {
      text-decoration: underline;
      color: $text-gray;
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        color: $primaryButtonHover;
      }
    }
  }
}

.cell-pricing-blur {
  filter: blur(5px);
}

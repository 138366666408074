.qu-print-brief {
}

@media print {
  @page {
    //margin: 0.5cm;
  }

  .qu-print-brief {
    .qu-brief {
      &-header {
        display: none;
      }

      &-footer {
        display: none;
      }

      &-main {
        min-height: initial;
        max-height: initial;
      }
    }

    .qu-header {
      display: none;
    }

    .qu-alert {
      display: none;
    }

    .button--link {
      display: none;
    }

    .ant-row.pt-40 {
      padding-top: 0;
    }

    .BSUnit {
      &__heading {
        flex: 0 0 134px;
      }

      &__content {
        padding-right: 0px;
      }
    }

    .brief {
      &-step {
        margin: 0;
        padding: 0;

        &-header {
          display: none;
        }
      }
    }
  }
}

.client {
  position: relative;

  &-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-content {
    max-width: 1000px;
    width: 100%;
  }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 44px);
    width: 100%;
    overflow: auto;
    background-color: $main-bg;
  }

  .description {
    height: 0;
  }

  .disabledField {
    z-index: 1000;
    background-color: rgb(246, 231, 231);
    opacity: 0.6;
    pointer-events: none;
  }

  .steps__item {
    width: calc(100% / 3);
  }

  .steps__item--current {
    border-width: 2px;
    font-weight: bold;
  }

  .cancelBtn {
    text-decoration: underline;
    cursor: pointer;
    color: #252727;
  }

  .subLine {
    margin-bottom: 10px;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .widthAgeGroups {
    width: 320px;
  }

  .productTypeLeft {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .form-card {
    max-height: 160px;
  }

  &-campaigns {
    margin-top: 15px;

    .qu-statistic-wrapper {
      margin-bottom: 18px;
    }

    .qt-header-campaigns {
      margin-bottom: 15px;
    }
  }

  &-brief-view {
    margin-top: 30px;
    margin-bottom: 30px;

    .button--link {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & .profile {
    max-height: calc(100vh - 56px);
    height: calc(100vh - 56px);

    &-sidebar {
      .SS .SS__header {
        background-color: #fafbfb;
      }

      .InfoCard {
        margin: 0;
      }
    }

    &-footer_custom {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 35px;

      & .right {
        display: flex;

        .qu-button {
          min-width: 150px;
        }
      }

      & .left {
        display: flex;
      }

      .qu-button {
        min-width: 150px;
      }
    }

    &-center_custom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    @media only screen and (max-width: 666px) {
      .productTypeLeft {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .line {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
      }

      &-sidebar {
        display: none;
      }

      .profile-main {
        margin: 0 20px;
      }

      >.qu-sidebar {
        display: none;
      }

      &-footer_custom {
        flex-direction: row-reverse;
        margin-top: 0;

        & .right {
          flex: 1;

          .qu-button {
            flex: 1;
            min-width: 150px;

            &:not(:first-child) {
              margin: 0 10px 10px 10px;
            }
          }
        }

        & .left {
          flex: 1;
          order: 1;
          margin-top: 0;

          .qu-button {
            flex: 1;
            min-width: 150px;

            &:not(:first-child) {
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      &-main {
        align-items: flex-start;
      }
    }
  }

  @media only screen and (max-width: 666px) {
    &-campaigns {
      // padding: 0 16px;
      margin-top: unset;

      .qt-header-campaigns {
        .qt-search {
          flex: 100%;
          margin-right: 0;
          // margin-bottom: 16px;
        }

        .qt-filter {
          flex: 100%;
        }
      }
    }

    &-brief-view {
      .button--link {
        margin-left: 10px;
        margin-bottom: 30px;
      }

      .brief-label-time {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .tax-treatment {
    .ant-radio-group {
      width: 100%;

      .qu-radio {
        margin-bottom: 5px;
        margin-right: unset;
      }
    }
  }
}
.auth {
  min-height: 100vh;
  background-color: $white;
  background-image: url("../../assets/login-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 37vw center;

  &-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // max-width: 720px;
    max-height: 100vh;
    width: 100%;
    min-height: 100vh;
    padding: 0;
    background-color: $white;
    overflow: auto;

    .auth-section-header-parent {
      flex: unset;

      .auth-header-section {
        height: 60px;
        padding: 0 16px;
        line-height: unset;
        background-color: black;
        display: flex;
        color: $white;

        .image-logo {
          float: left;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .auth-home-menu {
          float: right;
          background-color: black;
          border-bottom: unset;
          color: $white;
          font-size: 20px;
          font-weight: 500;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: auto;

          .auth-language-button {
            text-decoration: underline;
            color: $white;
            font-size: 16px;
            font-family: 'Bahij TheSansArabic Plain';
          }

          .auth-language-button:hover {
            text-decoration: underline;
          }

          li {
            margin-right: unset;
          }

          .ant-menu-item {
            color: unset;
            border-bottom: unset;
          }

          .ant-menu-item:hover {
            color: unset;
            border-bottom: unset;
          }
        }

        .auth-mobile-menu-dropdown {
          float: right;
          margin-left: auto;
        }

        .user-menu {
          font-size: 30px;
          color: $white;
        }

        .ant-dropdown {
          z-index: 9999;

          li span a {
            font-size: 16px;
            font-weight: 500;
            line-height: 24.2px;
            color: black;
          }
        }
      }
    }

    .auth-footer-section {
      background-color: black;
      min-height: 136px;

      .image-logo {
        width: 100px;
        margin-right: 20px;
      }
    }

    &-header {
      display: flex;
      padding: 20px 0;
      margin-bottom: 20px;

      svg {
        height: 36px;
      }
    }

    &-body {
      // min-height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: unset;
      padding-bottom: unset;
      justify-content: center;
      gap: 35px;
      margin: unset;

      .divider {
        height: 50vh;
        width: 1px;
        background-color: #f5f3f3;
      }
    }

    &-footer {
      text-align: center;
      padding-top: 10px;
      // padding-bottom: 69px;
      font-size: 15px;
      line-height: 19px;

      p {
        margin: 0;
      }
    }
  }

  &-form {
    align-self: center;
    max-width: unset !important;
    width: 400px !important;

    h1 {
      margin-bottom: 38px;
    }

    .account-selector {
      display: flex;
      width: 100%;

      &-help {
        margin-bottom: 20px;
        margin-top: 5px;
        color: $text-gray-light;
      }

      .qu-radio-button {
        flex: 1;
        text-align: center;
      }
    }

    .google-login-button {
      font-size: unset !important;
      font-family: unset !important;
      box-shadow: none !important;
      border: 1px solid #E6E6E6 !important;
      border-radius: 5px !important;

      div {
        background: unset !important;
        padding: unset !important;
        padding-top: 8px !important;
      }
    }

    .google-signin-hint {
      padding-top: 10px;
      text-align: center;
      color: #D15E28;
      font-weight: 500;
    }
  }

  .remember {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 25px;
  }

  &-terms {
    margin-top: 20px;
    margin-bottom: 10px;
    color: $text-gray-light;
    font-size: 15px;
    line-height: 19px;
  }

  &-captcha {
    margin-top: 5px;
    margin-bottom: 25px;
  }

  // custom home page design starts
  &-home-parallax {
    overflow-y: hidden;
    font-family: Inter;
    background: #060709;
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .home-header {
      height: 135px;
      padding: unset;
      line-height: unset;
      background: black;
      display: flex;
      align-items: center;

      .image-logo {
        float: left;
        width: 165px;
        margin-left: 40px;

        svg {
          width: 150px;
          height: 100%;
        }
      }

      .home-menu {
        float: right;
        background: black;
        border-bottom: unset;
        color: $white;
        font-size: 20px;
        font-weight: 500;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 20px;

        .create-account-button {
          text-transform: none;
          background-color: unset;
          color: $white;
          font-size: 16px;
          font-weight: 500;
          border: none !important;
          border-radius: 35px;
        }

        .create-account-button:hover,
        .create-account-button:active {
          text-decoration: underline;
          // background: #ffffff;
          // color: black;
          // border-radius: 35px;
        }

        .language-button {
          text-transform: none;
          background-color: $white;
          color: black;
          font-size: 16px;
          font-weight: 500;
          line-height: 24.2px;
          height: 50px;
          width: 110px;
          border-radius: 35px;
          border: 1px solid $white;
          font-family: 'Bahij TheSansArabic Plain';
        }

        .language-button:hover {
          background: black;
          color: $white;
        }

        li:hover {
          color: unset !important;
          border-bottom: unset !important;
        }

        .ant-menu-item {
          border-bottom: unset;
          margin-left: unset;
          margin-right: 10px;
        }

        .ant-menu-item-selected {
          color: unset;
        }

        a {
          color: $white;
        }

        a:hover {
          text-decoration: unset !important;
        }
      }

      .mobile-menu-dropdown {
        float: right;
        margin-left: auto;
        margin-right: 20px;
      }

      .user-menu {
        font-size: 30px;
        color: $white;
      }

      .ant-dropdown {
        z-index: 9999;

        li span a {
          font-size: 16px;
          font-weight: 500;
          line-height: 24.2px;
          color: black;
        }
      }
    }

    .first-section {
      display: flex;
      flex: 1;
      margin-bottom: 100px;
      background-color: $white;
      // max-height: 700px;
      // max-width: 1500px;
      margin: 0 auto;

      .left-side {
        flex: 1;
        background: black;
        color: $white;
        padding: 20px;
        box-sizing: border-box;
        z-index: 4000;
        border: 1px solid black;

        p {
          font-weight: 700;
          font-size: 43px;
          color: $white;
          position: relative;
          top: 10%;
          margin-inline-start: 20%;
          width: 80%;

          svg {
            vertical-align: -22%;
            width: 130px;
            height: 100%;
          }
        }

        .start-here-button,
        .start-here-button:active {
          border: 1px solid $white;
          color: black;
          top: 15%;
          margin-inline-start: 20%;
          background: $white;
          font-weight: 700;

        }

        .start-here-button:hover {
          color: $white;
          background: black;
        }
      }

      .right-side {
        flex: 2 0;
        overflow: hidden;
        z-index: 5000;
      }

      .right-side img {
        width: 100%;
        height: 90%;
        display: block;
      }

      .parallax-girl-img {
        position: relative;
        z-index: 9999;

        .image-bg {
          width: 100%;
          height: 700px;
          display: block;

          background-image: url("../../assets/home/img-femme-nobg.png");
          background-color: transparent;
          background-position: -44%;
          background-repeat: no-repeat;
          background-size: contain;
          margin-top: -60px;
          z-index: 5000;
          margin-inline-start: -5%;
        }
      }
    }

    .second-section {
      background-color: black;
      padding: 70px 0;
      text-align: center;

      .p1 {
        font-size: 50px;
        line-height: 77.45px;
        color: $white;
        margin-bottom: 20px;

        span {
          vertical-align: 5%;
          padding-right: 5px;
        }

        svg {
          vertical-align: bottom;
          width: 180px;
          height: 100%;
          margin-left: 10px;
        }
      }

      .p2 {
        font-weight: 500;
        font-size: 33px;
        line-height: 45px;
        color: $white;
        margin-bottom: unset;
      }
    }

    .third-section-1 {
      background-color: black;
      padding: 50px 0 10px 0;
      text-align: center;

      .p1 {
        font-size: 20px;
        color: $white;
        margin-bottom: unset;
        text-transform: uppercase;
      }

      .p2 {
        font-size: 40px;
        font-weight: 500;
        color: $white;
        margin-bottom: unset;
      }
    }

    .third-section-2 {
      padding: unset;

      .left-side {
        .p1 {
          font-size: 36px;
          color: $white;
          line-height: 48px;
          font-weight: bold;
          margin-inline-start: 20%;
        }

        .p2 {
          font-size: 18px;
          color: $white;
          line-height: 30px;
          margin-inline-start: 20%;
        }
      }

      .right-side {
        position: relative;
        top: 20px;

        iframe {
          border: none;
          width: 100%;
          height: 561px;
        }
      }
    }

    .third-section-3 {
      padding: 50px 0;

      .left-side {
        .left-side-div {
          margin-inline-start: 20%;

          iframe {
            border: none;
            width: 241px;
            height: 486px;
          }
        }
      }

      .right-side {
        .p1 {
          font-size: 36px;
          color: $white;
          line-height: 48px;
          font-weight: bold;
          margin-inline-end: 20%;
        }

        .p2 {
          font-size: 18px;
          color: $white;
          line-height: 30px;
          margin-inline-end: 20%;
        }
      }
    }

    .third-section-4 {
      padding: unset;

      .left-side {
        .p1 {
          font-size: 36px;
          color: $white;
          line-height: 48px;
          font-weight: bold;
          margin-inline-start: 20%;
        }

        .p2 {
          font-size: 18px;
          color: $white;
          line-height: 30px;
          margin-inline-start: 20%;
        }
      }

      .right-side {
        .iframes-container {
          display: flex;
          justify-content: center;
          scale: 0.8;
          position: relative;
          // top: 100px;

          .iframe-left {
            width: 303px;
            height: 600px;
            float: left;
          }

          .iframe-right {
            float: left;
            width: 553px;

            iframe {
              border: none;
            }

            .iframe-right-1 {
              width: 545px;
              height: 388px;
              display: block;
              padding-bottom: 4px;
            }

            .iframe-right-2 {
              width: 545px;
              height: 227px;
              display: block;
            }
          }
        }
      }
    }

    // .third-section {
    //   padding: 50px 0 50px 0;

    //   p {
    //     font-size: 40px;
    //     color: $white;
    //     padding-left: 60px;
    //     line-height: 58.09px;
    //     width: 63%;
    //     margin-inline-start: 15%;
    //   }

    //   .start-here-button,
    //   .start-here-button:active {
    //     background-color: transparent;
    //     background: transparent;
    //     border: 3px solid $white;
    //     border-radius: 50px;
    //     color: $white;
    //     margin-inline-start: unset;
    //     top: 40px;
    //   }

    //   .start-here-button:hover {
    //     border: 3px solid $white;
    //     background-color: $white;
    //     color: black;
    //     border-color: $white;
    //   }

    //   .parallax-laptop-img {
    //     z-index: 9999;
    //     position: relative;
    //     float: right;
    //   }

    //   .laptop-img {
    //     z-index: 9999;
    //     position: relative;
    //     float: right;
    //   }

    //   .parallax-tablet-img {
    //     position: absolute;
    //     right: 40%;
    //     z-index: 9999;
    //     bottom: 0;
    //   }

    //   .tablet-img {
    //     position: absolute;
    //     right: 40%;
    //     z-index: 9999;
    //     top: 25%;
    //   }

    //   .parallax-mobile-img {
    //     position: absolute;
    //     right: 32%;
    //     z-index: 9999;
    //     top: 44%;
    //   }

    //   .mobile-img {
    //     position: absolute;
    //     right: 32%;
    //     z-index: 9999;
    //     top: 44%;
    //   }
    // }

    .fourth-section {
      background-color: black;
      padding: 50px 0 100px 0;

      .p1 {
        text-align: center;
        padding-bottom: 40px;

        span {
          font-weight: 700;
          font-size: 45px;
          color: $white;
          margin-bottom: unset;
          text-align: center;
          line-height: 86px;
          vertical-align: unset;
          margin-inline-start: 15px;
        }

        svg {
          vertical-align: bottom;
          width: 170px;
          height: 100%;
          margin-left: 10px;
        }
      }

      .stats {
        .ant-col {
          text-align: center;
          padding: 8px 16px;
        }

        .top-labels {
          font-weight: 700;
          font-size: 40px;
          color: $white;
          line-height: 77.45px;
        }

        .bottom-labels {
          font-weight: 500;
          font-size: 20px;
          color: $white;
          line-height: 38.73px;
        }
      }
    }

    .fifth-section {
      // margin: 150px 0 0 0;
      padding: 0;
      background-color: $white;
      text-align: center;

      // .dashboard-img {
      //   z-index: 9999;
      //   position: relative;
      //   width: 92%;
      //   float: inline-end;
      //   margin-inline-start: 4%;
      // }

      // p {
      //   font-size: 40px;
      //   color: black;
      //   line-height: 58.09px;
      //   text-align: center;
      //   width: 70%;
      //   margin-bottom: unset;
      //   margin-inline-start: 100px;
      // }

      .p1 {
        font-size: 36px;
        color: black;
        line-height: 48px;
        font-weight: bold;
        margin-inline-end: 20%;
        text-align: left;
      }

      .p2 {
        font-size: 18px;
        color: black;
        line-height: 30px;
        margin-inline-end: 20%;
        text-align: left;
      }

      .lottie-svg-class {
        width: calc(max(340px, 40vw)) !important;
      }
    }

    .sixth-section {
      padding: 0;
      background-color: $white;
      text-align: center;

      // .companies-img {
      //   z-index: 9999;
      //   position: relative;
      //   float: inline-end;
      //   margin-inline-end: -4%;
      //   width: 92%;
      // }

      // p {
      //   font-size: 40px;
      //   color: black;
      //   text-align: center;
      //   margin-bottom: unset;
      //   line-height: 58.09px;
      //   margin-inline-start: 150px;
      // }

      .p1 {
        font-size: 36px;
        color: black;
        line-height: 48px;
        font-weight: bold;
        margin-inline-start: 20%;
        text-align: left;
      }

      .p2 {
        font-size: 18px;
        color: black;
        line-height: 30px;
        margin-inline-start: 20%;
        text-align: left;
      }

      .lottie-svg-class {
        width: calc(max(340px, 40vw)) !important;
      }
    }

    @media (max-width: 515px) {
      .third-section-3 .left-side .left-side-div .first-iframe {
        display: none;
      }
    }

    @media (max-width: 768px) {
      .first-section {
        .left-side {
          text-align: center;
          padding: 50px 10px;

          p {
            font-weight: 500;
            font-size: 30px;
            top: unset;
            margin-inline-start: unset;
            width: unset;

            svg {
              width: 110px;
            }
          }

          .start-here-button,
          .start-here-button:active {
            top: unset;
            margin-inline-start: unset;
            font-weight: 700;
          }
        }
      }

      .second-section {
        padding: 25px 10px;

        .p1 {
          font-size: 35px;
          line-height: 50px;

          svg {
            width: 120px;
            height: 100%;
            margin-left: 10px;
          }
        }

        .p2 {
          font-size: 20px;
          line-height: 30px;
        }
      }

      // .third-section {
      //   padding: 25px 10px;

      //   p {
      //     text-align: center;
      //     width: unset;
      //     font-size: 25px;
      //     padding-left: unset;
      //     line-height: 40px;
      //     margin-inline-start: unset;
      //   }

      //   .start-here-button,
      //   .start-here-button:active {
      //     top: 10px;
      //     margin-bottom: 50px;
      //   }

      //   .parallax-mobile-img {
      //     bottom: 0;
      //     top: unset;

      //     img {
      //       width: 60%;
      //       max-width: unset;
      //     }
      //   }
      // }

      .third-section-1 {
        padding: 50px 0 20px 0;

        .p1 {
          font-size: calc(20px - 25%);
        }

        .p2 {
          font-size: calc(40px - 25%);
        }
      }

      .third-section-2 {
        padding: 15px 0;

        .left-side {
          .p1 {
            font-size: calc(36px - 25%);
            line-height: calc(48px - 25%);
            padding: 0 20px;
            margin-inline-start: unset;
          }

          .p2 {
            font-size: calc(18px - 25%);
            line-height: calc(30px - 25%);
            padding: 0 20px;
            margin-inline-start: unset;
            margin-bottom: unset;
          }
        }
      }

      .third-section-3 {
        padding: 15px 0;

        .left-side {
          text-align: center;

          .left-side-div {
            margin-inline-start: unset;
          }
        }

        .right-side {
          .p1 {
            font-size: calc(36px - 25%);
            line-height: calc(48px - 25%);
            padding: 10px 20px 0 20px;
            margin-inline-end: unset;
          }

          .p2 {
            font-size: calc(18px - 25%);
            line-height: calc(30px - 25%);
            padding: 0 20px;
            margin-inline-end: unset;
            margin-bottom: unset;
          }
        }
      }

      .third-section-4 {
        padding: 15px 0;

        .left-side {
          .p1 {
            font-size: calc(36px - 25%);
            line-height: calc(48px - 25%);
            padding: 0 20px;
            margin-inline-start: unset;
          }

          .p2 {
            font-size: calc(18px - 25%);
            line-height: calc(30px - 25%);
            padding: 0 20px;
            margin-inline-start: unset;
            margin-bottom: unset;
          }
        }

        .right-side {
          .iframes-container {
            scale: unset;
            top: 20px;
            text-align: center;
            margin: 0 10px;

            .iframe-left {
              float: unset;
            }

            .iframe-right {
              display: none;
              width: 100%;

              .iframe-right-1 {
                width: 100%;
              }

              .iframe-right-2 {
                width: 100%;
                height: 227px;
                display: block;
              }
            }
          }
        }
      }

      .fourth-section {
        padding: 50px 0;
        margin-top: unset;

        .p1 {
          padding-bottom: 10px;

          span {
            font-weight: 700;
            font-size: 25px;
            line-height: 50px;
            margin-inline-start: unset;
          }

          svg {
            width: 120px;
            height: 100%;
          }
        }

        .stats {
          .ant-col {
            padding: unset;
          }

          .top-labels {
            font-weight: 700;
            font-size: 25px;
            line-height: 50px;
          }

          .bottom-labels {
            font-weight: 500;
            font-size: 15px;
            line-height: 25px;
          }
        }
      }

      .fifth-section {
        padding-top: 25px;
        margin: unset;

        .p1 {
          // font-size: 25px;
          // line-height: 40px;
          // width: unset;
          // margin-inline-start: unset;
          font-size: 30px;
          padding: 0 10px;
          text-align: center;
          margin-inline-end: unset;
        }

        .p2 {
          // font-size: 25px;
          // line-height: 40px;
          // width: unset;
          // margin-inline-start: unset;
          font-size: 15px;
          padding: 0 10px;
          text-align: center;
          margin-inline-end: unset;
        }

        // .dashboard-img {
        //   float: unset;
        //   margin-inline-start: unset;
        //   width: 100%;
        //   padding: 0 5px;
        // }
      }

      .sixth-section {
        padding: 25px 0 25px 0;

        // p {
        //   font-size: 25px;
        //   line-height: 40px;
        //   margin-inline-start: unset;
        // }

        // .companies-img {
        //   float: unset;
        //   margin-inline-end: unset;
        //   width: 100%;
        // }
        .p1 {
          // font-size: 25px;
          // line-height: 40px;
          // width: unset;
          // margin-inline-start: unset;
          font-size: 30px;
          padding: 0 10px;
          text-align: center;
          margin-inline-start: unset;
        }

        .p2 {
          // font-size: 25px;
          // line-height: 40px;
          // width: unset;
          // margin-inline-start: unset;
          font-size: 15px;
          padding: 0 10px;
          text-align: center;
          margin-inline-start: unset;
        }
      }
    }

    @media (min-width:768px) and (max-width: 1199px) {
      .first-section {
        .left-side {
          p {
            font-weight: 500;
            font-size: 35px;
            margin-inline-start: 10%;

            svg {
              width: 120px;
            }
          }

          .start-here-button,
          .start-here-button:active {
            top: 10%;
            margin-inline-start: 10%;
            font-weight: 600;
          }
        }

        .parallax-girl-img {
          .image-bg {
            height: 500px;
            margin-top: 0px;
            margin-inline-start: -15%;
          }
        }
      }

      // .third-section {
      //   padding: 50px 20px;

      //   p {
      //     width: unset;
      //     font-size: 25px;
      //     padding-left: unset;
      //     line-height: 40px;
      //     margin-inline-start: unset;
      //     padding: 0 10px;
      //   }

      //   .start-here-button,
      //   .start-here-button:active {
      //     top: 30px;
      //   }

      //   .parallax-mobile-img {
      //     bottom: 0;
      //     top: unset;

      //     img {
      //       width: 70%;
      //     }
      //   }
      // }

      .fifth-section {
        padding-top: 25px;
        margin: unset;

        // p {
        //   font-size: 25px;
        //   line-height: 40px;
        //   width: unset;
        //   margin-inline-start: unset;
        // }

        // .dashboard-img {
        //   float: unset;
        //   margin-inline-start: unset;
        //   width: 100%;
        //   padding: 0 5px;
        // }
      }

      .sixth-section {
        padding: 25px 0 25px 0;

        // p {
        //   font-size: 25px;
        //   line-height: 40px;
        //   margin-inline-start: unset;
        // }

        // .companies-img {
        //   float: unset;
        //   margin-inline-end: unset;
        //   width: 100%;
        // }
      }
    }

    @media (min-width:768px) and (max-width: 1056px) {
      .third-section-1 {
        padding: 50px 0 20px 0;

        .p1 {
          font-size: calc(20px - 15%);
        }

        .p2 {
          font-size: calc(40px - 15%);
        }
      }

      .third-section-2 {
        padding: 15px 0;

        .left-side {
          .p1 {
            font-size: calc(36px - 15%);
            line-height: calc(48px - 15%);
            // padding: 0 20px;
            // margin-inline-start: unset;
          }

          .p2 {
            font-size: calc(18px - 15%);
            line-height: calc(30px - 15%);
            // padding: 0 20px;
            // margin-inline-start: unset;
            // margin-bottom: unset;
          }
        }
      }

      .third-section-3 {
        padding: 15px 0;

        .left-side {
          .left-side-div {
            display: flex;
            margin: 0 20px;
            scale: 0.8;
          }
        }

        .right-side {
          .p1 {
            font-size: calc(36px - 15%);
            line-height: calc(48px - 15%);
            padding: 10px 20px 0 20px;
            // margin-inline-end: unset;
          }

          .p2 {
            font-size: calc(18px - 15%);
            line-height: calc(30px - 15%);
            padding: 0 20px;
            // margin-inline-end: unset;
            // margin-bottom: unset;
          }
        }
      }

      .third-section-4 {
        padding: 15px 0;

        .left-side {
          .p1 {
            font-size: calc(36px - 15%);
            line-height: calc(48px - 15%);
            // padding: 0 20px;
            // margin-inline-start: unset;
          }

          .p2 {
            font-size: calc(18px - 15%);
            line-height: calc(30px - 15%);
            // padding: 0 20px;
            // margin-inline-start: unset;
            // margin-bottom: unset;
          }
        }

        .right-side {
          .iframes-container {
            display: flex;
            justify-content: center;
            scale: 0.5;
            top: unset;
          }
        }
      }
    }

    @media (min-width: 1056px) and (max-width: 1300px) {
      .third-section-3 .left-side .left-side-div {
        display: flex;
        margin: 0 60px;
      }

      .third-section-4 {
        .right-side {
          .iframes-container {
            display: flex;
            justify-content: space-between;
            scale: 0.6;
            top: unset;
          }
        }
      }
    }

    @media (min-width: 1600px) {
      .first-section {
        .parallax-girl-img {
          .image-bg {
            height: 1000px;
            background-image: url("../../assets/home/img-femme-nobg.png");
            background-color: transparent;
            background-position: -24%;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 5000;
            margin-inline-start: -7%;
          }
        }
      }

      .third-section-1 {
        .p1 {
          font-size: calc(20px + 25%);
        }

        .p2 {
          font-size: calc(40px + 25%);
        }
      }

      .third-section-2 {
        .left-side {
          .p1 {
            font-size: calc(36px + 25%);
            line-height: calc(48px + 25%);
          }

          .p2 {
            font-size: calc(18px + 25%);
            line-height: calc(30px + 25%);
          }
        }
      }

      .third-section-3 {
        .left-side {
          .left-side-div {
            scale: 1.1;
            margin-inline-start: 25%;
          }
        }

        .right-side {
          .p1 {
            font-size: calc(36px + 25%);
            line-height: calc(48px + 25%);
          }

          .p2 {
            font-size: calc(18px + 25%);
            line-height: calc(30px + 25%);
          }
        }
      }

      .third-section-4 {
        .left-side {
          .p1 {
            font-size: calc(36px + 25%);
            line-height: calc(48px + 25%);
          }

          .p2 {
            font-size: calc(18px + 25%);
            line-height: calc(30px + 25%);
          }
        }

        .right-side {
          .iframes-container {
            display: flex;
            justify-content: center;
            // scale: 1;
            top: unset;
            // margin-inline-end: 5%;
          }
        }
      }

      // .third-section {
      //   .devices-right-section {
      //     padding-right: 200px;

      //     .parallax-laptop-img {
      //       bottom: 0;
      //       top: unset;

      //       img {
      //         width: 150%;
      //         max-width: unset;
      //         padding-right: 100px;
      //       }
      //     }

      //     .parallax-tablet-img {
      //       bottom: 0;
      //       top: unset;

      //       img {
      //         width: 130%;
      //         max-width: unset;
      //         padding-right: 100px;
      //       }
      //     }

      //     .parallax-mobile-img {
      //       bottom: 0;
      //       top: unset;

      //       img {
      //         width: 110%;
      //         max-width: unset;
      //         padding-right: 100px;
      //       }
      //     }
      //   }
      // }

      .fourth-section {
        .p1 {
          span {
            font-size: 55px;
          }
        }

        .stats {
          .top-labels {
            font-size: 50px;
          }

          .bottom-labels {
            font-size: 30px;
          }
        }
      }
    }

    .seventh-section {
      padding: 60px 10px 30px 10px;
      background-color: black;

      p {
        font-size: 35px;
        color: $white;
        text-align: center;
        margin-bottom: unset;
        line-height: 50px;
      }
    }

    @media (max-width: 767px) {
      .seventh-section {
        padding: 60px 10px;

        p {
          font-size: 15px;
          line-height: 30px;
        }
      }
    }

    @media (max-width: 1199px) {
      .seventh-section {
        padding: 60px 10px 30px 10px;
      }
    }

    .eighth-ninth-section {
      // position: relative;

      .create-ad-button {
        text-align: center;
        padding: 0 0 60px 0;

        .start-here-button,
        .start-here-button:active {
          background: transparent;
          border: 3px solid #ffffff;
          border-radius: 50px;
          color: #ffffff;
        }

        .start-here-button:hover {
          border: 3px solid $white;
          background-color: $white;
          color: black;
          border-color: $white;
        }

      }

      @media (max-width: 767px) {
        .create-ad-button .start-here-button {
          font-size: 10px;
        }
      }

      .eighth-section {
        padding-bottom: 100px;
        position: relative;
        z-index: 9999;

        .signup-section {
          border: 1px solid $white;
          border-radius: 10px;
          padding: 30px;
          background: black;
          z-index: 9999;
          max-width: 600px;

          .ant-input::placeholder {
            color: #606368;
            font-size: 15px;
            font-weight: 500;
          }

          .ant-form-item {
            margin-bottom: 10px;

            .ant-input {
              height: 50px;
            }
          }

          .form-footer {
            text-align: center;
            padding-top: 20px;

            .submit-button {
              font-size: 13px;
              font-weight: 600;
              margin-left: 10px;
              background: $white;
              color: black;
              border-radius: 15px;
              text-transform: unset;
              height: 45px;
              width: 115px;
              line-height: 24.2px;
              border: 1px solid $white;
            }

            .submit-button:hover {
              background: black;
              color: $white;
            }

            .or-footer-div {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 20px 0;

              .or-line {
                height: 1px;
                width: 100%;
                background-color: $white;
              }

              .or-text {
                margin: 0 20px;
                color: $white;
                font-size: 15px;
                font-weight: 400;
                line-height: 26.63px;
              }
            }

            .google-login-section {
              .google-login-button {
                font-size: unset !important;
                font-family: unset !important;
                box-shadow: none !important;
                border: 1px solid #E6E6E6 !important;
                border-radius: 15px !important;
                height: 60.84px;
                text-transform: none;

                span {
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 20px;
                }

                div {
                  background: unset !important;
                  padding: unset !important;
                  padding-top: 8px !important;
                }
              }

              .google-signin-hint {
                font-size: 15px;
                padding: 10px 0;
                text-align: center;
                color: yellow;
                font-weight: 400;
              }
            }
          }

          h1 {
            font-weight: 600;
            font-size: 25px;
            color: $white;
            padding-bottom: 30px;
            line-height: 36.31px;
            text-align: center;
          }

          p {
            font-weight: 400;
            font-size: 15px;
            color: $white;
            line-height: 20px;
            text-align: center;
          }
        }
      }

      .ninth-section {
        background-color: $white;
        padding: 10px 0;
        // position: absolute;
        // bottom: 0;
        width: 100%;

        p {
          margin-bottom: unset;
          display: flex;
          align-items: center;

          img {
            margin-left: 70px;
            cursor: pointer;
          }

          svg {
            margin-left: 70px;
          }

          span {
            margin-left: auto;
            font-weight: 400;
            font-size: 13px;
            line-height: 24.2px;
            color: black;
            margin-bottom: unset;
            float: right;
            height: 80px;
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
        }

        .powered-text {
          span {
            font-weight: 400;
            font-size: 13px;
            line-height: 24.2px;
            color: black;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
              font-style: italic;
              color: black;
              font-weight: unset;
            }
          }
        }

        @media (max-width: 575px) {
          p span {
            height: 30px;
            margin: 0 auto;
          }
        }
      }

      @media (max-width: 767px) {
        .ninth-section {
          padding: unset;

          p {
            svg {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }

  // custom home page design ends

  @media only screen and (max-width: 480px) {
    &-section {
      max-height: initial;

      &-header {
        padding-top: 40px;
        margin-bottom: 10px;
        justify-content: center;
      }

      &-body {
        flex-direction: column;

        .divider,
        .divider-container {
          display: none;
        }
      }

      &-footer {
        padding-top: 55px;
        padding-bottom: 38px;
      }
    }

    &-form {
      width: 300px !important;

      h1 {
        text-align: center;
        font-size: 25px;
        line-height: 30px;
      }

      .thankyou__text {
        text-align: center;
      }

      .thankyou__desc {
        text-align: center;
      }
    }

    &-terms {
      width: 275px;
      font-size: 13px;
      line-height: 18px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    &-form {
      width: 400px !important;
      max-width: 340px !important;
    }
  }
}

.remove-auth-extras {
  min-height: auto;
  background-image: none;
}

.login-home-modal {
  z-index: 9999 !important;
  background: #ffffff96;
  font-family: Inter;

  .ant-modal-body {
    padding: 0 24px;
  }

  .login-home-section {

    .signup-section {
      padding: 30px;
      margin: 0 auto;

      p {
        text-align: center;
      }

      .ant-input::placeholder {
        color: #606368;
        font-size: 15px;
        font-weight: 500;
      }

      .ant-form-item {
        margin-bottom: 20px;

        .ant-input {
          height: 50px;
        }
      }

      .form-footer {
        text-align: center;

        .submit-button {
          font-size: 13px;
          font-weight: 600;
          margin-left: 10px;
          background: black;
          color: $white;
          border-radius: 15px;
          text-transform: unset;
          height: 45px;
          width: 115px;
          line-height: 24.2px;
          border: 1px solid black;
        }

        .submit-button:hover {
          background: $white;
          color: black;
        }
      }

      h1 {
        font-weight: 600;
        font-size: 30px;
        color: black;
        padding-bottom: 30px;
        line-height: 36.31px;
        text-align: center;
      }

      h3 {
        font-weight: 400;
        font-size: 15px;
        color: black;
        padding-bottom: 20px;
        line-height: 26.63px;
        text-align: center;
      }
    }
  }
}
.announcement {
  position: relative;

  &-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-content {
    width: 100%;

    .spinner-component {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 158px);
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 44px);
    width: 100%;
    overflow: auto;
  }
}

.qu-partners {
  &-content {
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    width: 100%;
  }

  &-header {
    h1 {
      display: flex;
      margin-bottom: 5px;
      line-height: 32px;
    }

    p {
      color: $text-gray;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 19px;
    }
  }

  &-search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: -10px;
  }

  @media only screen and (max-width: 666px) {
    &-header {
      p {
        margin-bottom: 0;
      }
    }

    // &-search {}

    &-content {
      padding: 25px 20px;
    }

    // &-grid {}
  }
}

.co-branding {
  padding-bottom: 20px;

  .qu-header {
    height: 70px;
    padding: unset !important;
  }

  .partner-title {
    text-align: center;
    font-size: 25px;
    // width: 500px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0 auto;
  }

  .partner-desc-para {
    margin: 20px auto;
    text-align: center;
    font-size: 15px;
    color: rgba(30, 37, 43, 0.85);
    line-height: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .partner-desc-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .partner-logo {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin: 30px;
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-transform: unset;
  }

  .partner-logo-button {
    margin: 20px 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-transform: unset;
    font-weight: 500;
  }

  .reCaptcha {
    display: flex;
    justify-content: center;
  }

  .footer {
    text-align: center;
    margin-top: 70px;

    a.switch {
      color: #D15E28;
      font-size: 13px;
    }
  }
}

.partner-logo-header {
  background-color: unset !important;
  height: 80px !important;
  flex-wrap: unset !important;
  padding: unset !important;

  .partner-logo-brief {
    margin-left: auto !important;
    margin-right: auto !important;
    height: 75px !important;
  }
}

.partner-logo-qu-brief-main {
  max-height: calc(100vh - 209px) !important;
  min-height: calc(100vh - 209px) !important;
}

.client-preview-container {
  max-width: 735px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 10px 0;

  &-header {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      padding-right: 10px;
      padding-left: 10px;
    }

    @media (max-width: 479px) {
      flex-wrap: wrap;

      .qu-button {
        width: 100%;
      }
    }

    .qu-button-wrapper {
      margin-left: auto;
      text-decoration: none;
    }
  }

  &-percentage {
    margin-left: 15px;
    padding: 3px 12px 2px;
    font-weight: 700;
    letter-spacing: 0.88px;
    color: #fff;
    border-radius: 15px;

    @media (min-width: 768px) {
      font-size: 15px;
    }

    @media (max-width: 767px) {
      font-size: 13px;
    }
  }

  &-body {
    .BS {
      &-container {
        margin-bottom: 35px;

        &-mobile {
          margin-top: 10px;
          padding: 20px;

          &:last-child {
            margin-bottom: 35px;
          }
        }
      }

      &__body {
        padding-top: 35px;
        padding-bottom: 40px;
      }
    }

    .h6 {
      font-weight: 500;
    }

    .profile-preview {
      &-person {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      &-image {
        overflow: hidden;

        @media (min-width: 768px) {
          width: 144px;
          height: 144px;
          border-radius: 5px;
        }

        @media (max-width: 767px) {
          width: 110px;
          height: 110px;
          border-radius: 50%;
        }

        &.no-image {
          background-color: #bcbebf;
        }

        img {
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .info-row {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        margin-top: 15px;
      }

      &-key {
        flex-basis: 140px;
        min-width: 140px;
        margin-right: 30px;

        @media (min-width: 768px) {
          opacity: 0.8;
        }

        @media (max-width: 767px) {
          opacity: 0.6;
          font-size: 14px;
        }
      }

      &-value {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
      }

      &-icon {
        flex-shrink: 0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        margin-right: 15px;
        background-color: #dcebf3;
        border-radius: 7px;

        svg {
          max-width: 24px;
          max-height: 24px;
        }

        .icon-gender {
          transform: translateX(2px);
        }
      }

      &-icon__white {
        background-color: #ffffff;
      }

      p {
        margin-bottom: 0;

        @media (min-width: 768px) {
          margin-top: 6px;
        }
      }
    }

    .product-types {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 768px) {
        padding-top: 15px;
      }

      @media (max-width: 767px) {
        margin-right: -5px;
        margin-left: -5px;
        padding-top: 5px;
      }
    }
  }
}
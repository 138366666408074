@media only screen and (max-width: 666px) {
  .qu-brief-layout {
    .qu-brief-v2-header {
      .welcome {
        font-size: 15px;
        color: #fff;
        padding: 5px 10px;
        align-items: center;
        height: 40px;
      }
    }

    .qu-header-controls {
      background-color: #FFFFFF;
      border-radius: 30px 30px 0px 0px;
    }

    .available-services {
      padding: 0 0 40px 0;
      margin: -10px 10px;

      .single-service {
        margin: 10px 0;
      }
    }

    .qu-footer {
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #FFF;
      width: 100%;
      flex-wrap: wrap;
      height: auto;
      padding: 5px 0;

      .footer-buttons {
        margin: 0 auto !important;

        button {
          width: 150px;
        }
      }
    }
  }

  .AvailableServiceModal__thumb {
    position: relative;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    background-color: #FFFFFF;

    img {
      object-fit: cover;
      height: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .AvailableServiceModal__header {
    .AvailableServiceModal__header-info {
      align-self: center;
      display: flex;
      flex-direction: column;
      color: #1E252B;
      font-size: 17px;
      line-height: 1.2;
      padding-top: 10px;

      .AvailableServiceModal__header-name {
        color: #1E252B;
        font-size: 15px;
        font-weight: 600;
        line-height: 19px;
        margin-bottom: 10px;
      }

      .AvailableServiceModal__header-description {
        font-size: 13px;
        line-height: 19px;
        color: #60676A;
        margin-bottom: 10px;

        .text-heading {
          color: #1E252B;
          font-weight: 600;
          margin-right: 5px;
        }
      }
    }
  }
}
.html-rtl {
  direction: rtl !important;
  font-family: 'Bahij TheSansArabic Plain' !important;


  .qu-uploader .upload-button span {
    margin-right: 5px;
  }

  .ant-modal-title {
    margin: 0;
    margin-right: 20px;
  }

  .services-item-qty {
    margin-right: 0px;
    margin-left: 15px;
  }

  .notifications-menu-popover .ant-badge-count {
    direction: ltr;
  }

  .auth-section-footer {
    .user-menu-icon {
      margin-right: inherit;
      margin-left: 10px;
    }
  }

  .refer.only-desktop {
    right: -140px;
    left: inherit;

    .refer-button svg {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &.layout.auth {
    background-position: -15vw center;

    .auth-section-header-parent {
      flex: unset;

      .auth-header-section {
        .image-logo {
          float: unset;
          margin-left: unset;
          margin-right: unset;
        }

        .auth-home-menu {
          margin-right: auto;
          margin-left: unset;
          float: unset;
        }

        .auth-mobile-menu-dropdown {
          float: left;
          margin-right: auto;
          margin-left: unset;
        }
      }
    }

    .auth-footer-section {
      .image-logo {
        margin-right: unset;
        margin-left: 20px;
      }
    }
  }

  &.auth-home-parallax {
    background-position: -15vw center;

    .home-header {
      .image-logo {
        float: unset;
        margin-left: unset;
        margin-right: 40px;
      }

      .home-menu {
        margin-right: auto;
        margin-left: 20px;
      }

      .mobile-menu-dropdown {
        float: left;
        margin-right: auto;
        margin-left: 20px;
        margin-bottom: 10px;
      }
    }

    .first-section {
      .parallax-girl-img {
        .image-bg {
          background-position: 85%;
        }
      }
    }

    @media (min-width:768px) and (max-width: 1199px) {
      .first-section {
        .left-side {
          p {
            font-size: 30px;

            svg {
              width: 100px;
            }
          }
        }

        .parallax-girl-img {
          .image-bg {
            background-position: 80%;
          }
        }
      }
    }

    // @media (min-width: 1600px) {
    //   .third-section {
    //     .devices-right-section {
    //       padding-right: unset;
    //     }
    //   }
    // }

    .sixth-section {
      .companies-img {
        margin-inline-start: 4%;
        float: inline-start;
        margin-inline-end: unset;
      }

      @media (max-width: 767px) {
        .companies-img {
          float: unset;
          margin-inline-start: unset;
        }
      }

      @media (max-width: 1199px) {
        .companies-img {
          float: unset;
          margin-inline-start: unset;
        }
      }
    }

    .eighth-ninth-section {
      .ninth-section {
        p {
          span {
            margin-left: 10px;
            margin-right: auto;
          }

          svg {
            margin-right: 70px;
          }
        }

        .powered-text {
          span a {
            font-style: italic;
          }
        }

        @media (max-width: 575px) {
          p span {
            margin: 0 auto;
          }

          p svg {
            margin: 0 auto;
          }
        }

        @media (min-width: 576px) and (max-width: 767px) {
          p {
            svg {
              margin-right: 15px;
            }

            span {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .q-table_menu.ant-dropdown-trigger {
    right: inherit;
    left: 10px;
  }

  .ant-select-arrow {
    right: inherit;
    left: 11px;
  }

  .BSUnit__edit {
    right: inherit;
    left: 0;
  }

  .BSUnit__list .i {
    margin-right: 0px;
    margin-left: 7px;
  }

  .ant-input-prefix {
    margin-right: 0;
    margin-left: 4px;
  }

  .q-table-list .check-icon {
    margin: 0 0px 0 5px;
  }

  .q-table-product_title {
    text-align: start;
  }

  .q-table-product_thumb {
    margin-right: 0px;
    margin-left: 10px;
  }

  .q-table .ant-table-tbody>tr>td {
    text-align: start;
  }

  .ant-table-thead>tr>th {
    text-align: start;
  }

  .ant-dropdown-menu {
    direction: rtl;
    text-align: start;

    .user-menu-icon {
      margin-top: -1px;
      margin-left: 10px;
    }
  }

  .qu-header-logo {
    svg {
      width: 100px;
    }
  }

  .user-menu {
    margin-left: 0;
    margin-right: 0;

    .user-name {
      margin-right: 15px;
      margin-left: 0;
    }
  }

  .button--link {
    margin-right: 10px;

    .button__icon:first-child {
      transform: rotate(180deg);
      margin-left: 4px;
      margin-right: -4px;
    }
  }

  //brief edit containter
  .form-brief {

    .form__number {
      margin-right: inherit;
      margin-left: 16px;
    }

  }

  .qu-brief-footer {
    .footer-center .qu-button:not(:last-child) {
      margin-right: 0;
      margin-left: 10px;
    }

    .footer-right .qu-button {
      right: inherit;
      left: 0;
    }
  }

  .qu-brief-header .header-stepper {
    margin-left: 0;
    margin-right: -174px;
  }

  .qu-brand-label {
    .qu-brand-logo {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  //Cart Summary Card
  .cart-summary__edit {
    float: left;
  }

  .cart-summary__dlist dt {
    float: right;
  }

  .SSUnit__descriptions .label-column small {
    direction: ltr;
    text-align: right;
  }

  .ProfileCard__logo {
    margin-right: 0;
    margin-left: 14px;
  }

  .ProfileCard__services-item:not(:last-child) {
    margin-right: 0;
    margin-left: 10px;
  }

  .ProfileCard__services-item .i {
    margin-right: 0;
    margin-left: 9px;
  }

  @media (min-width: 666px) and (max-width: 1366px) {
    .ProfileCard__services-item .i {
      margin-right: 0;
      margin-left: 4px;
    }
  }

  .ProfileCard__tags {
    margin-right: auto;
    margin-left: inherit;
  }

  .client-preview-container-header .qu-button-wrapper {
    margin-left: inherit;
    margin-right: auto;
  }

  .client-preview-container-percentage {
    margin-left: inherit;
    margin-right: 15px;
  }

  .qu-brief-main {
    margin-right: 0;
    margin-left: 16px;
  }

  .SSUnit {
    .SSUnit__heading {
      padding-right: 0;
      padding-left: 40px;
    }

    .SSUnit__edit {
      left: 0;
      right: inherit;
    }

    .SSUnit__thumb {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .layout.profile {
    .client-preview-container-body .info-row-key {
      margin-right: 0;
      margin-left: 30px;
    }

    .PartnerProfileSidebar div.current .index {
      transform: translate(120%, 0);
    }

    .PartnerProfileSidebar div.finished div.index,
    .PartnerProfileSidebar div.unfinished .index {
      transform: translate(120%, 0);
      top: 20%;
    }
  }

  .qu-details-aside {
    .button__counter {
      margin: 0 4px 0 -4px;
    }
  }

  .partner-layout {
    .ant-table-tbody {

      th,
      td {
        text-align: start;
      }
    }
  }

  //campaign details
  .reports_listing {
    margin: 15px;
  }

  .reports_listing div {
    float: left;
  }

  .reports_listing .check_report_icon {
    margin-right: inherit;
    margin-left: 10px;
  }

  .reports_listing .date_span {
    margin-right: inherit;
    margin-left: 27px;
  }

  //pagination
  .qu-pagination {
    direction: ltr;
  }

  //header logo
  .new-header-logo-container {
    left: inherit;
    right: 44px;
  }

  //notifications menu
  .notifications-menu-wrapper {
    .ant-popover-content {
      direction: rtl;
      text-align: start;
    }

    .ant-popover-arrow {
      right: inherit;
      left: 12px;
    }

    .notifications-menu-image {
      margin-right: inherit;
      margin-left: 15px;
    }
  }

  //alignment
  .mr-5 {
    margin-right: inherit;
    margin-left: 5px;
  }

  .qu-uploader .single-image_mini-container .details_wrapper {
    padding-left: unset;
    padding-right: 5px;
  }

  .qu-summary-button {
    left: 8px;
    right: unset;
  }

  .qu-request-list {
    .request-card-image {
      margin-right: unset;
      margin-left: 15px;
    }

    .only-view-icon {
      span {
        padding-right: 5px;
      }
    }
  }

  .product-brief-upload,
  .brief-upload-step {
    .details_wrapper {
      text-align: right !important;
      padding-right: 10px !important;
    }
  }

  .online_payment_sub_label {
    padding-left: unset !important;
    padding-right: 35px;
  }
}

.delete-brief-modal {
  .ant-modal-title {
    padding-right: 20px;
  }
}

.html-rtl.user-menu-list {
  direction: rtl;
  text-align: start;

  .user-menu-icon {
    margin-top: -1px;
    margin-left: 10px;
  }
}
.brief {
  &-label-time {
    margin-top: 35px;

    h3 {
      font-size: 20px;
      line-height: 24px;
    }

    span {
      font-size: 12px;
      line-height: 15px;
      color: rgba($text-black, 0.6);
    }
  }

  @media only screen and (max-width: 480px) {
    &-list {
      &-header {
        display: flex;
        margin-bottom: 15px;

        h2 {
          font-size: large;
        }

        +div .qt-search,
        +div .qt-filter {
          flex: 100%;
          margin: 0 16px;
        }
      }
    }
  }
}

.qu-brief-list .q-table .ant-table .cursorPointer {
  cursor: pointer;
}

.ant-carousel .slick-dots-bottom {
  bottom: -10px !important;
}

.ant-carousel .slick-dots li {
  background-color: grey !important;
  width: 5px !important;
  border-radius: 100% !important;
  height: 5px !important;
  text-indent: unset !important;

  // button {
  //   display: none !important;
  // }
}

.ant-carousel .slick-dots button {
  background-color: grey !important;
  width: 5px !important;
  border-radius: 100% !important;
  height: 5px !important;
  text-indent: unset !important;

  // button {
  //   display: none !important;
  // }
}

.ant-carousel .slick-dots li.slick-active {
  background-color: #D9563A !important;
  width: 5px !important;
  border-radius: 100% !important;
  height: 5px !important;
  text-indent: unset !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #D9563A !important;
  width: 5px !important;
  border-radius: 100% !important;
  height: 5px !important;
  text-indent: unset !important;
}

.ant-carousel .slick-initialized .slick-slide {
  padding: 5px;
}

.mobile-pagination {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  font-weight: 500;
  color: #D15E28;

  .left-arrow {
    float: left;
    color: #D15E28;
    padding-top: unset;
  }

  .left-arrow[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .right-arrow {
    float: right;
    color: #D15E28;
    padding-top: unset;
  }

  .right-arrow[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
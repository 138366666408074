.brief-view {
  &-mobile {
    &-media {
      margin: 0;
      align-items: center;
      justify-content: start;

      .BSUnit__bList-item {
        align-items: start;
        display: flex;
        flex-direction: column;
        text-align: start;
        margin: 0;
      }

      .BSUnit__bList-item {
        margin: 10px 10px 5px 0;
      }
    }
  }
}

.SS {
  background-color: #ffffff;

  &Unit__sLabel.rejected svg path {
    fill: $error;
  }
}

.proposal-actions {
  margin-top: 20px;
}

@media only screen and (max-width: 666px) {
  .proposal-actions {
    margin: 20px 10px 0 10px;
  }
}

.ant-form-item {
  &.mb-0 {
    margin-bottom: 0;
  }
}

.progress_container {
  .ant-progress {
    position: fixed;
    z-index: 1;
  }
}

.status-title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 38px;

  h1 {
    margin-right: 24px;
    line-height: 33px;
  }
}

.qu-header-right {
  position: relative;
}

.refer {
  &.only-desktop {
    position: absolute;
    left: -140px;
  }
}

.ant-dropdown-menu-item {
  .discount-item {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }
  }
}

.qu-metrics-card {
  .ant-card-body {
    padding: 0;
  }

  .ant-card-grid {
    padding: 0;
  }
}

.currency_dropdown {
  width: 100px;
}

.cursorPointer {
  cursor: pointer;
}

.qu-header-logo svg {
  visibility: hidden;
}

.qu-header-logo {
  // background-image: url('https://i.ibb.co/ww6nQJw/home-1.png');
  //   height: 26px;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   filter: brightness(100);
}

.qu-details-main .qu-details-thumbnail img {
  object-position: center;
}

// .qu-header{
//   background-color: #67b116;
// }

.services-form-controls {
  position: fixed;
  bottom: 0;
  width: 244px;
}

.ant-notification {
  z-index: 1050 !important;
}

.product-sub-type {
  font-size: 14px;
}

.reCaptchaHeight iframe {
  height: unset !important;
}

.promotion-container {
  background: white;
  border-radius: 10px;
  min-height: 320px;
  box-sizing: border-box;
  margin-right: 15px;
  box-shadow: 0 3px 6px 4px #e9e9e9;
}

.promotion-container-inverse {
  background-color: transparent;
  box-shadow: none;
}

.promotion-container img {
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-position: center center;
  margin-bottom: 10px;
}

.show-all-container {
  height: 310px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #D9563A;
  cursor: pointer;
}

.show-all-container label {
  cursor: pointer;
}

.dotted-border {
  border: 1px dashed #e6e6e6;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px;
}

.new-header-logo-container {
  height: 44px;
  position: absolute;
  z-index: 100;
  left: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-header-logo-container img {
  max-height: 44px;
  object-fit: contain;
  object-position: center;
}

.mx-auto {
  margin: 0px auto;
}

.text-justify {
  text-align: justify;
}

#arabic-loader-container {
  min-height: 100vh;
  min-width: 100%;
  background-color: white;
  position: absolute;
  z-index: 2000;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 666px) {
  .new-header-logo-container {
    display: none;
  }

  .go-to-top {
    display: none;
  }

  .status-title {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 30px;

    h1 {
      text-align: center;
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
}

.ant-notification {
  direction: ltr !important;
}

.cell-type {
  gap: 5px;
}

#brief_quotation_print_area {
  min-width: 1020px;
}

.d-none {
  display: none !important;
}

.ant-descriptions-item-label {
  word-break: break-word;
}

.data-view-label,
.data-view-value {
  word-break: break-word;
}

.w-100-percent {
  width: 100%;
}

.persist-whitespace {
  white-space: pre;
}

.force-show {
  display: block !important;
}

.required-label {
  margin-top: -3px;
  font-size: 15px;
  font-weight: 500;
  word-break: break-word !important;
}

.required-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}


.mx-auto {
  display: block;
  margin: 0px auto;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.accept-v2 {
  background-color: #8ECB9F;
  flex-grow: 1;
  color: white;
  border-radius: 10px !important;
}

.reject-v2 {
  background-color: #EF471F;
  flex-grow: 1;
  color: white;
  border-radius: 10px !important;
}

.rejection-reason {
  background: #EF471F;
  color: white;
  padding: 0px 15px;
  border-radius: 5px;
}

.text-size-14 {
  font-size: 14px !important;
}

.primary-text {
  color: #D15E28;
}

.secondary-text {
  color: #173994;
}

.v3-modal {
  .ant-modal {
    max-width: unset !important;
    margin: unset !important;
    top: 0px;
  }
}


.v3-edit-container {
  .qu-uploader .single-image_mini-container {
    height: auto;
    max-height: 100px !important;
    min-height: 50px !important;
  }

  .qu-uploader .single-image_mini-container .details_wrapper .title {
    margin: 0;
    max-width: 100px;
    font-size: 13px;
    line-height: 26px;
  }

  .qu-uploader .single-image_mini-container .details_wrapper {
    padding-left: 15px;
    justify-content: center;
    min-width: 100px;
  }

  .qu-uploader .single-image_mini-container .actions {
    display: flex;
    justify-content: unset;
    gap: 10px;
  }

  .qu-uploader .single-image_mini-container .image {
    position: initial;
    min-height: 51px !important;
    height: 100%;
    width: auto;
    max-width: 200px !important;
    object-position: center center;
    object-fit: contain !important;
    border-radius: 5px;
  }

  .qu-uploader .upload-button {
    font-size: 13px;
    /* align-items: flex-end; */
    // width: 340px;
    // height: 50px;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    text-decoration: underline;
    color: black;
  }

  .qu-button:hover {
    color: #333 !important;
    border: 0px !important // border:1px solid black !important;
  }
}

.html-output-div {
  background-color: #f6f6f6;
  height: 200px !important;
  overflow: auto;
  height: auto;
  position: relative;
}

.grecaptcha-badge {
  display: none !important;
}

.language-btn {
  align-self: center;
}

.auth-header-section {
  justify-content: space-between;
}
.deployment-details {
  .qu-details-main img {
    object-fit: contain !important;
    padding: 0 10px !important;
  }

  .qu-details-body {
    padding-bottom: unset;

    // .qu-services-form {
    //   justify-content: unset;
    // }

    .qu-services-description {
      margin-bottom: unset;
    }

    .social-list .i {
      margin-left: 5px;
    }
  }

  .qu-details-body .only-mobile {
    display: block !important;
  }

  .auth-section-footer {
    padding: unset;
    margin: 20px 0 0 0;
  }

  @media (max-width: 380px) {
    .qu-details-body {
      padding-left: 10px;
      padding-right: 10px;
    }

    .qu-details-logo {
      height: 40px;
      width: 40px;
      min-width: 40px;
      margin-right: unset;
    }

    .qu-details-title {
      max-width: 280px;
    }
  }
}

.deployment-details-ar {
  .qu-services-thumbnail {
    margin-right: unset;
    margin-left: 15px;
  }

  .social-list .i {
    margin-right: unset;
    margin-left: 5px;
  }
}
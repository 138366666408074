.payment-sheet-container {
  height: 1428px;
  align-items: stretch;

  &.flex-column,
  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-sb {
    justify-content: space-between;
  }

  .pt0 {
    padding-top: 0;
  }

  .sub-container {
    padding: 30px;

    .heading {
      h1,
      h2 {
        padding-left: 15px;
        color: #5b6475;
      }

      h1 {
        font-size: 35px;
      }
    }

    &-top {
      background-color: white;
      padding-top:50px;

      img {
        width: auto;
        height: 150px;
        margin-bottom:10px;
      }
    }

    &-top-grey {
      height: 30%;
      background-color: #f8f8f8;

      img {
        width: 30%;
      }
    }

    &-mid {
      background-color: white;
      flex-grow: 2;

      .grey {
        background-color: #f8f8f8;
      }

      .section {

        margin: 20px;

        &-first {
          width: 70%;
          padding: 15px;
        }

        &-second {
          width: 30%;
          padding: 15px;
        }

        span {
          color: black;
        }

        .title {
          color: #1479ac;
          font-size: 25px;
          padding: 15px 0;
        }

        .subtitle {
          color: #D9563A;
          text-decoration: underline;
          font-size: 18px;
          padding: 15px 0;
        }

        &.grey-bg {
          background-color: #f8f8f8;
        }
      }
    }

    &-bottom {
      height: 40px;
      align-items: stretch;
      position: relative;

      .footer-orange {
        background-color: #D9563A;
        flex-grow: 1;
      }

      .footer-blue {
        background-color: #1479ac;
        flex-grow: 1;
      }
    }

    &-bottom::before {
      position: absolute;
      content: '';
      width: 40px;
      height: 40px;
      border-top: 42px solid white;
      border-left: 40px solid transparent;
      bottom: 0;
      right: -1px;
    }
  }

  table.fees-breakdown {
    margin-top: 15px;
    width: 100%;

    th,
    td {
      color: black;
      padding: 10px;
      border: 1px solid #bfbfbf;
    }

    th {
      color: #D9563A;
      font-size: 18px;
    }

    td.payment-label {
      text-align: end;
    }
  }
}

.dashboard {
  &-layout {
    height: 100%;
    max-height: calc(100vh - 56px);
    background-color: $main-bg;
  }

  &-content {
    max-width: 1000px;
    width: 100%;

    >h1 {
      margin-bottom: 32px;
    }

    .ant-radio-group-solid {
      .qu-radio-button {
        font-size: 14px;
      }
    }
  }

  &-aside {
    margin-bottom: 32px;
  }

  &-tabs {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  &-table-wrapper {
    margin-top: 16px;
    margin-bottom: 30px;

    .q-table .ant-table {
      box-shadow: $inputShadow;

      .cursorPointer {
        cursor: pointer;
      }
    }

    &.partner-table-wrapper {
      margin-top: 0;

      >.pt-20 {
        padding-top: 10px;
      }
    }
  }

  @media only screen and (max-width: 666px) {
    &-layout {
      .qt-search {
        flex: 1;
      }
    }

    &-content {
      >h1 {
        margin-bottom: 10px;
      }
    }

    &-aside {
      margin-bottom: 10px;
    }
  }
}

.client-layout {
  @media only screen and (max-width: 666px) {
    .qu-header .qu-button {
      font-size: 13px !important;
      color: #1379AC;
      font-weight: 600 !important;
      border-bottom: 4px solid #dddddd;
      padding: unset;
    }

    .qu-header-controls {
      background-color: #FFFFFF;
      border-radius: 30px 30px 0px 0px;
    }

    .qu-header .qu-button:hover,
    .qu-header .qu-button.active {
      background: none;
      border-color: #46B0E6;
      opacity: 1;
      border-bottom: 4px solid #46B0E6;
      padding: unset;
    }

    .qu-header .user-menu .welcome {
      font-size: 13px;
    }

    .qu-header-right {
      align-items: center;
      min-height: 60px;
    }

    .qu-header {
      padding: 0 15px 15px 15px;
    }

    .qu-header .user-menu .ant-avatar {
      width: 40px;
      height: 40px;
      border: 2px solid;
    }
  }
}
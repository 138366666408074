// Color
$primary: #D9563A;
$blue: #1379ac;
// $dark-blue: #0b5f95;
$dark-blue: #67b116;

$light-blue: #2392d1;
$green: #00a126;
$green-alt: #6dd084;
$light-green: #9edfad;
$brightest-green: #f1f9f3;
$brightest-red: #f8f4f1;
$lightpink: #f9d8cf;
$pink: #f39680;
$black: #1e252b;
$gray: #5b6475;
$mid-gray: #b8bcc2;
$light-gray: #f4f5f7;
$white: #ffffff;
$violet: #b489c5;

$orange: rgb(234, 107, 39);
$orange-light: #ffb288;

$error: #e2474b;
$success-green: #5cc073;

$modal-bg: #f6f7f8;
$main-bg: #f4f5f7;
$title-bg: rgba(246, 247, 248, 0.7);
$gray-icon: #999999;

$text-black: $black;
$text-gray: #73777d;
$text-gray-dark: #29353f;
$text-gray-light: #6e8590;
$list-gray: #60676a;

$primaryButton: $primary;
$primaryButtonHover: #c05422;
$primaryButtonActive: #ae4818;

$primaryOutline: rgba(209, 94, 40, 0.3);
$primaryOutlineHover: rgba(255, 92, 33, 0.06);
$primaryOutlineActive: rgba(255, 92, 33, 0.15);

$primarySoft: rgba($primary, 0.2);
$primarySoftHover: rgba($primary, 0.3);
$primarySoftActive: rgba($primary, 0.4);

$greenHover: #019021;
$greenActive: #016f1a;

$blueHover: #0e6088;
$blueActive: #105174;

$border-default: #d9d9d9;

$inputIconColor: rgba($blue, 0.6);

$inputBorderHover: rgba($blue, 0.4);
$inputBorderActive: rgba($blue, 0.8);
$inputShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

$labelRejected: #ef471f;
$labelPending: #e5b086;
$labelApproved: #8ecb9f;
$labelRunning: #65aed7;
$labelCompleted: #b1bdd3;

$alertInfoColor: $light-blue;
$alertInfoBg: rgba($alertInfoColor, 0.05);
$alertInfoBorder: rgba($alertInfoColor, 0.5);

$alertWarningColor: $orange;
$alertWarningBg: rgba($alertWarningColor, 0.05);
$alertWarningBorder: rgba($alertWarningColor, 0.5);

$alertSuccessColor: $success-green;
$alertSuccessBg: rgba($alertSuccessColor, 0.05);
$alertSuccessBorder: rgba($alertSuccessColor, 0.5);

$alertErrorColor: $error;
$alertErrorBg: rgba($alertErrorColor, 0.05);
$alertErrorBorder: rgba($alertErrorColor, 0.5);

$bg-buttons: rgb(234, 234, 234);
// Fonts
$font-bold: 'hk-grotesk-bold', Helvetica, Arial, sans-serif;
$font-semibold: 'hk-grotesk-semibold', Helvetica, Arial, sans-serif;
$font-medium: 'hk-grotesk-medium', Helvetica, Arial, sans-serif;
$font-regular: 'hk-grotesk-regular', Helvetica, Arial, sans-serif;
$font-light: 'hk-grotesk-light', Helvetica, Arial, sans-serif;

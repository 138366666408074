//Statuses
.status-label {
    border-radius: 8px !important;
    padding: 8px !important;
}

.status-label.approved {
    background-color: #0F39DD !important; //blue
}

.status-label.completed, .status-label.running {
    background-color: #808080 !important; //grey
}

.status-label.ongoing,
.status-label.accepted,
.client-preview-container-percentage.badge--match-90 {
    background-color: #2BC155 !important; //green
}

.status-label.pending {
    background-color: #EB4B2D !important; //red
    color: white !important;
}
@import '../variables.scss';

.partner {
  display: flex;
  background-color: $main-bg;

  .steps__item--current {
    border-width: 2px;
    font-weight: bold;
  }

  &-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-main {
    align-items: center;
    //max-height: calc(100vh - 56px);
    height: 100%;
    padding: 0 80px;
    overflow: auto;

    .PartnerHeaderMobile {
      width: 100%;

      label {
        font-weight: 500;
        text-align: center;
        width: 50%;
        font-size: 14px;
      }

      label.qu-radio-button.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        background: white;
        color: $blue;
        border: 1px solid $bg-buttons;
      }

      label.qu-radio-button.ant-radio-button-wrapper {
        border: 1px solid $bg-buttons;
      }

      label.qu-radio-button.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:before {
        background: $bg-buttons;
        border: 1px solid $bg-buttons;
      }

      label.qu-radio-button.ant-radio-button-wrapper:before {
        background: $bg-buttons;
      }

      label.qu-radio-button.ant-radio-button-wrapper {
        background: $bg-buttons;
        color: black;
      }
    }

    .dynamic-sample-pricing-label {
      padding: 15px 0 15px 0;
      color: grey;
      font-weight: 500;
      font-size: 15px;
      line-height: 1.2;
      text-decoration: underline;
    }

    .dynamic-sample-price-input-label {
      color: #c6c4c4;
    }
  }

  &-content {
    max-width: 1000px;
    width: 100%;
    min-height: calc(100vh - 56px);
    grid-area: main;
    margin: 0;

    .qu-brief-footer {
      background: none;
      box-shadow: none;
    }

    .partner-footer_custom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .right {
        display: flex;
        align-items: center;

        button {
          margin: 0 10px;
          width: 150px;
        }
      }
    }
  }

  &-header {
    grid-area: header;
  }

  &-sidebar {
    //max-width: 1000px;
    //width: 100%;
    //min-height: calc(100vh - 56px);
    grid-area: sidebar;
    padding: 0 40px;

    .SS .SS__header {
      background-color: #fafbfb;
    }
  }

  &-brief-view {
    .dashboard-tabs {
      margin-top: 0;
    }

    .qu-statistic-wrapper {
      margin-bottom: 10px;

      h1 {
        display: none;
      }
    }

    .partner-requests-tab {
      .q-table .ant-table {
        box-shadow: none;
      }
    }
  }

  .dashboard-table-wrapper .cursorPointer,
  .qt-body .cursorPointer {
    cursor: pointer;
  }

  @media only screen and (max-width: 666px) {
    &-main {
      padding: 0 20px;
    }

    > .qu-sidebar {
      display: none;
    }

    .qt-header-campaigns {
      .qt-search {
        flex: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }

      .qt-filter {
        flex: 100%;
      }
    }

    .qu-statistic {
      &-list {
        &.qu-statistic-dubble {
          justify-content: flex-start;

          .qu-statistic-item:not(:last-child) {
            margin-right: 30px;
          }
        }
      }
    }

    &-brief-view {
      padding-top: 10px;

      .button--link {
        margin-bottom: 30px;
      }

      .qu-radio-group.ph-10 {
        padding: 0;
      }

      .qu-view-card.m-10 {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    &-main {
      align-items: flex-start;
    }
  }
}

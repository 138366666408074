.partner-container {
  max-height: calc(100vh - 56px);
  height: 100%;
  align-items: start;

  .InfoCard__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  &-footer {
    background: none;
    box-shadow: none;
    grid-area: footer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .right {
      display: flex;
      align-items: center;

      .qu-button {
        flex: 1;
        min-width: 150px;

        &:not(:first-child) {
          margin: 0 0 0 10px;
        }
      }
    }
  }

  &-header {
    grid-area: header;

    .steps .steps__item {
      flex: 1 1 auto;
      white-space: nowrap;
    }
  }

  &-sidebar {
    grid-area: sidebar;
    border-radius: 10px;
    background-color: rgba(246, 247, 248, 0.5);

    .PartnerProfileSidebar .InfoCard {
      margin: 0;
      height: min-content;
    }

    .hr-8 {
      height: 1px;
      width: 236px;
      background-color: #000000;
      opacity: 0.05;
      margin: 0 auto;
    }

    .InfoCard__body {
      background: none;

      .description {
        height: min-content;
      }
    }

    .we-encourage-you-to {
      opacity: 0.7;
      color: #212930;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 20px 0;
    }
  }

  &-content {
    grid-area: content;

    .first_content {
      .PPField {
        padding: 0;
      }

      .PPField:nth-child(1) {
        padding-top: 20px;
        border: none;
        padding-bottom: 10px;
      }

      .PPField:nth-child(2) .ant-row.ant-form-item {
        margin: 0;
      }

      .PPField:nth-child(2) {
        padding-bottom: 10px;
      }

      .PPField:nth-child(3) {
        padding: 20px 0 10px 0;
      }

      .PPField:nth-child(3) .ant-row.ant-form-item {
        margin: 0;
      }

      .PPField:nth-child(4),
      .PPField:nth-child(5) {
        padding-top: 20px;
      }

      .PPField:nth-child(5) .ant-row.ant-form-item {
        margin: 0;
      }

      .PPField:nth-child(6) {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .PPField:nth-child(5) {
        padding-bottom: 20px;
      }

      .PPField__input p {
        color: gray;
        padding: 10px 0;
      }

      .PPField:nth-child(4) {
        padding-bottom: 20px;
      }

      .PPField:nth-last-child(2) .ant-row:nth-child(1) .ant-input {
        background: url('../../../assets/icons/media-types/facebook-link.svg') no-repeat 10px center;
        padding: 7.5px 35px;
        margin: 5px 0;
      }

      .PPField:nth-last-child(2) .ant-row:nth-child(2) .ant-input {
        background: url('../../../assets/icons/media-types/instagram-link.svg') no-repeat 10px center;
        padding: 7.5px 35px;
        margin: 5px 0;
      }

      .PPField:nth-last-child(2) .ant-row:nth-child(3) .ant-input {
        background: url('../../../assets/icons/media-types/twitter-link.svg') no-repeat 10px center;
        padding: 7.5px 35px;
        margin: 5px 0;
      }
    }

    .second_content {
      .PPField:nth-child(2),
      .PPField:nth-child(3) {
        .PPField__input .ant-form-item-control {
          flex-direction: row;

          div {
            width: 58%;
          }

          div:nth-child(2) {
            display: flex;
            align-items: center;
            width: 42%;
            padding-left: 10px;

            div {
              width: 100%;
              font-size: 14px;
            }
          }
        }
      }

      .PPField {
        padding: 5px 0;
      }

      .PPField:nth-child(3) .ant-row.ant-form-item {
        margin: 0;
      }

      .PPField:nth-child(1) .PPField__description {
        padding: 11px 0 0 0;
      }

      .PPField:nth-child(1),
      .PPField:nth-child(4) {
        padding-top: 20px;
      }

      .PPField:nth-child(4) {
        padding-bottom: 0;
      }

      .PPField:nth-child(6) {
        padding: 20px 0 30px 0;
      }

      .PPField:nth-child(5) {
        padding-bottom: 10px;
      }

      .PPField .PPField__input {
        padding-bottom: 0;
      }

      .PPField:nth-child(2) {
        height: 49px;
      }

      .PPField__input p {
        color: gray;
        font-size: 13px;
        margin: 5px 0 10px 0;
      }

      .PPField:nth-child(1),
      .PPField:nth-child(2),
      .PPField:nth-child(4) {
        border: none;
      }
    }

    .third_content {
      .PPField:nth-child(1) {
        padding: 20px 0 20px 0;
      }

      .PPField:nth-child(2) {
        padding: 20px 0 0 0;

        .ant-row {
          display: flex;
          justify-content: center;
        }
      }

      .PPField:nth-last-child(1) {
        padding: 20px 0 30px 0;
      }
    }

    .fourth_content {
      .PPField {
        padding: 0;
        border: none;

        .fs-12 .form-card__label {
          font-size: 12px;
        }
      }

      .height146 {
        min-height: 146px;
      }

      .PPField .ant-row.ant-form-item {
        margin: 5px 0;
      }

      .PPField:nth-child(1) {
        padding: 30px 0 0 0;
      }

      .pricePer {
        width: 50%;
      }

      .PPField:nth-last-child(2) {
        border-bottom: 1px solid rgba(30, 37, 43, 0.05);
        padding-bottom: 10px;
      }

      .PPField:nth-last-child(3) {
        padding: 0 0 0 0;
      }

      .PPField:nth-last-child(1) {
        padding: 20px 0 30px 0;
      }

      .PPField:nth-child(1) {
        padding-top: 20px;
      }
    }

    .input {
      position: relative;

      .closeIcon {
        position: absolute;
        right: 0;
        top: 20px;
        transform: translate(-50%, -50%);
        background: white;
      }
    }
  }

  @media only screen and (max-width: 666px) {
    &-header {
      .steps .steps__item {
        span {
          display: none;
        }
      }
    }

    .partner-container-content .partner-container-sidebar {
      display: none;
    }
    .second_content {
      .PPField:nth-child(1) .PPField__description {
        padding: 11px 0 10px 0;
      }

      .PPField:nth-child(2) {
        margin-bottom: 30px;
      }

      .PPField:nth-child(2) .ant-form-item-control-input,
      .PPField:nth-child(3) .ant-form-item-control-input {
        width: 100%;
      }

      .PPField:nth-child(5) {
        padding-top: 10px;
      }

      .PPField:nth-child(6) {
        padding-top: 0;
      }
    }

    .third_content {
      .PPField:nth-child(1) label.qu-radio {
        margin: 0 5px;
      }

      .PPField:nth-child(2) .ant-row .ant-col:nth-child(1) label {
        font-size: 12px;
      }
    }

    .fourth_content {
      .PPField .ant-radio-group.ant-radio-group-outline {
        margin: 0 auto;
        width: 100%;
        display: flex;

        .qu-radio.ant-radio-wrapper {
          margin: 5px 5px;
        }
      }

      .PPField:nth-child(2) .ant-input.ant-input-lg.qu-input,
      .PPField:nth-child(4) .ant-input.ant-input-lg.qu-input {
        width: 100%;
      }

      .PPField:nth-last-child(3) {
        .PPField__input {
          margin-bottom: 10px;
        }

        .PPField__description {
          padding: 0;
        }
      }

      .ant-radio-group.ant-radio-group-outline,
      .ant-input.ant-input-lg.qu-input {
        margin: 0 auto;
        width: 100%;

        .qu-radio.ant-radio-wrapper {
          margin: 5px 0;
        }
      }
    }

    .second_content {
      .PPField:nth-child(1) .PPField__description {
        padding: 11px 0 10px 0;
      }

      .PPField:nth-child(2) {
        margin-bottom: 30px;
      }

      .PPField:nth-child(2) .ant-form-item-control-input,
      .PPField:nth-child(3) .ant-form-item-control-input {
        width: 100%;
      }

      .PPField:nth-child(5) {
        padding-top: 10px;
      }

      .PPField:nth-child(6) {
        padding-top: 0;
      }
    }

    &-footer {
      flex-wrap: wrap;

      > .qu-button-soft {
        order: 1;
        width: 100%;
        margin-top: 10px;
      }

      .right {
        width: 100%;
      }
    }
  }
}
